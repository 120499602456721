<template>
  <div class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height blog blog-detail">
            <div class="card-header d-flex justify-content-center">
              <div class="header-title ">
                <h5 class="card-title text-success">服務條款</h5>
              </div>
            </div>
            <div class="card-body">
              <div class="blog-description mt-3">
                <p>私域SEEWE（https://www.seewe.vip）及其子網域（以下合稱「本平台」或「我們」）是一個可以讓您與本平台之其他會員所張貼分享或販售的原始影音或服務進行預覽、瀏覽、使用、留言、分享、購買及互相交流的網路影音平台。</p>
                <p>為保障您使用本平台的相關權益，請於使用本平台前，務必詳細閱讀以下服務條款（以下簡稱「本條款」）。</p>
                <p>
                  本平台有權利於任何時間修改或變更本條款之任何內容，並參考所收到之申訴、有權管轄機關之建議及相關單位之建議，隨時配合法規修正，定期滾動式調整本條款，您應隨時注意本條款是否有任何更新、調整、修改或變更（以下合稱更新）；若您於本條款有任何更新後仍繼續使用本平台，視為您已經閱讀、瞭解並同意更新後的條款；若您不同意本條款內容的一部分或全部，或您所在地區或國家的法令法規排除本條款內容的一部份或全部時，您應立即停止使用本平台。
                </p>
                <p>第1條、定義</p>
                <ul>
                  <li>(1)「使用」是指您在本平台預覽、瀏覽、尋找、查看、使用、留言、分享、購買及互相交流適用於您的行動裝置、電腦、電視或其他受支援裝置（以下簡稱「裝置」）內容的行為。</li>
                  <li>(2)「內容」是指本平台提供或創作者上傳至本平台供您免費或付費使用的原始影音、創作、著作或服務。</li>
                  <li>(3)「同意」是指任何人知悉、明白、瞭解、允許且願意接受某一件事物。</li>
                  <li>(4)「監管」是指監督、管理、批准或審查、查驗、驗證或認證等管理方式。</li>
                  <li>(5)「成人」是指符合任一人符合居住地當地法令法規之有行為能力人資格或達到法定年齡，且身心年齡均客觀達到一般成人標準之人。</li>
                  <li>(6)「模特兒會員」是指一般會員向本平台提出申請並同意《模特兒會員條款》後之特別會員身份。</li>
                  <li>(7)「創作者會員」是指一般會員向本平台提出申請並同意《創作者會員條款》後之特別會員身份。</li>
                </ul>
                <p>第2條、前提</p>
                <ul>
                  <li>(1) 您一旦開始使用本平台，即表示您已經詳細閱讀並同意本條款的所有內容，您同時也同意確實遵守本條款與本平台相關之管理規範。</li>
                  <li>(2) 本平台可能有限定成人使用之內容，如果本平台之內容設有限制級警示或限定成人警示，一旦您同意限制級警示或限定成人警示，並開始使用本平台時，即推定您符合您所在國家/地區所要求的成人資格，若您未符合本項要求時切勿使用本平台；若您未符合本項要求仍繼續使用本平台時，您應自行負擔因此所產生之法律責任。</li>
                  <li>(3) 本平台絕不容忍任何涉及未成年或兒童的內容，包含但不限於任何虛構、幻想或模擬內容；本平台對戀童癖、未成年性行為、暴力、違反性自主及相關或類似內容等任何違法法令規定的內容均採取零容忍政策，且我們將會主動向本平台所在地之權責單位回報任何上述非法內容。</li>
                </ul>
                <p>第3條、嚴格年齡驗證</p>
                <ul>
                  <li>
                    為遵守⟪兒童及少年性剝削防制條例⟫第49條及⟪iWIN網路內容防護機構⟫之年齡驗證機制，本平台採用相對應之年齡驗證系統，以確保每一位購買本平台內容之會員皆非未成年或兒童；當您購買本平台之相關商品，並以「超商」或「ATM轉帳」做為支付內容價金之方式時，您同意配合本平台之相關年齡驗證系統，並誠實輸入您的相關認證資料（包含但不限於手機號碼及身份證號碼等），以確認您本人非未成年或兒童，本平台對前述您所提供的個人資料作為認證資料並無搜集、儲存、處理或利用，故不負擔個人資料保護法之相關責任。
                  </li>
                </ul>
                <p>第4條、會員註冊</p>
                <ul>
                  <li>(1) 若您要成為本平台的會員，以使用本平台及其他創作者所張貼分享或販售的原始影音或服務，您應該於建立會員帳號時提供您本人正確、最新且完整的個人資料，並使用具有獨特性且能代表您本人的使用者名稱，此使用者名稱不得含有不雅文字、侵害他人名譽、侵犯第三人的智慧財產權或冒犯他人之情事，同時您應設置一組具有獨特性的密碼以保障您會員帳號的安全。</li>
                  <li>(2) 除非經過有權利人的同意或充分授權外，您不得擅自以任何目的為他人或以他人之名義建立任何帳號。</li>
                  <li>(3) 您所建立之帳號僅限於本平台目的範圍內使用，不得進行任何非屬本平台目的範圍之活動。</li>
                  <li>(4) 本平台不接受以任何未經授權方式（包含但不限於使用自動裝置、惡意程式碼、病毒、木馬、資料擷取、封包擷取或其他類似方式）建立本平 台帳號，並得隨時關閉此類可疑帳號。</li>
                  <li>(5) 本平台無法對會員之真實身分進行完整的管理，也因此您在透過本平台與任何第三人互動時應小心謹慎，並採取適當的預防措施；本平台不會 監視或參與您與任何第三人於本平台上之間的互動，亦不會干涉您與任何第三人於本平台上的衝突，因此請您務必謹慎行事。</li>
                  <li>(6) 您有權且可以隨時更新您在本平台的帳號個人資料，或修改及調整您的帳號設定。</li>
                  <li>(7) 您同意所有於平台註冊的會員均為獨立的客體，均應獨自承擔其權利義務，並不得解釋、視為或推定任何會員為本平台的員工或承包商。</li>
                  <li>(8) 若您進一步成為本平台之創作者或模特兒時，除了進一步的條款外，您仍應繼續遵守本條款。</li>
                  <li>(9) 若本平台有接獲通知或主動發現帳號註冊資料異常時，本平台得逕行要求帳號重新驗證，其驗證過程包含要求重新提供身分證明資料、重新驗證手機門號、重新設定密碼、或以通話或通訊軟體等方式確認帳號資料...等，若無法完成重新驗證時，本平台有權依據第10條關閉您的帳號使用。</li>
                </ul>
                <p>第5條、帳號安全</p>
                <ul>
                  <li>(1) 您同意不會揭露您的帳號及登入資訊予任何第三人，並不會讓任何人登入或使用您的帳號，且不會做任何可能危害您帳號安全的行為。</li>
                  <li>(2) 您應對您帳號所發生的任何事件負責，並自行對您於本平台的登入資訊負保密義務並自行定期更新與維護，本平台不會因為非可歸責於本平台 的原因所造成您帳號的損失負擔賠償責任，您同時也同意不因前述情況對本平台有任何主張。</li>
                  <li>(3) 若您有任何發現獲合理懷疑任何違反帳戶安全的行為時，包含但不限於遺失、遭竊、非授權登入、非授權使用、登入資訊遺失等，您應該立刻 通知本平台，並提供可供證明您個人身分的資料後，由本平台協助您修改密碼或登入資訊。</li>
                  <li>(4) 我們無法確保您於本平台上提供個人資料予本平台外之任何第三人的個人資料安全及隱私權益，我們不建議您透過本平台直接提供任何個人資 訊予任何第三人；若您選擇透過本平台與任何第三人進行互動的同時提供您的個人資料予該第三人，您應自行承擔因此所生的任何風險。</li>
                </ul>
                <p>第6條、內容使用</p>
                <ul>
                  <li>(1) 本平台授權您一個非專屬、不可轉讓、僅供個人使用、可撤銷的授權供您以私人的、隱私的、非商業的以及娛樂的目的使用內容。</li>
                  <li>(2) 本平台之內容僅供您以個人於本平台上使用，任何於本平台外之使用，或商業目的之使用，均視為違反本條款，您必須對違反本條款造成本平 台任何損害負損害賠償責任。</li>
                  <li>(3) 任何於本平台上進行的非法、違法或違反本條款的活動均是被禁止的，若因此而產生任何法律責任，悉由您自行承擔，與本平台無涉。</li>
                  <li>(4) 當您夠透過本平台使用內容時，您應該尊重任何第三人，而不嘗試侵犯或冒犯他人，或妨害他人名譽；若有前述事項發生時，此乃屬於您與第 三人之間之爭議，悉與本平台無涉。</li>
                  <li>(5) 本平台不會事前以直接或間接的方式對任何人於本平台上所提供的內容進行監管。</li>
                  <li>(6) 您瞭解且同意您在本平台上所設定公開的個人資料以及您在本平台所上傳的內容可能會被任何第三人透過公共搜尋引擎（包含但不限於Yahoo或Google等）看見，請您務必謹慎設定個人資料隱私權設定，以及謹慎考量您所上傳的內容；若您希望從公共搜尋引擎移除您部分或全部的公開個人資料或上傳內容，您應向公共搜尋引擎經營者提出。</li>
                  <li>(7) 本平台理解及同意您，您可以將您所上傳到您帳號的內容，分享到各式各樣的第三方社群媒體或第三方社群網站，但您必須知悉且同意本平台 並沒有監管任何社群媒體或社群網站，也因此您必須單獨向您所分享的社群媒體或社群網站負責，並遵守其條款。</li>
                  <li>(8) 非經本平台同意，您不得任意散布或重製本平台之任何著作，即便本平台同意您散布或重製本平台之任何著作，本平台仍保留最後解釋之權 利。</li>
                  <li>(9) 若本平台收到相關權責單位之檢舉，本平台將會無條件於收到通知後24小時內下架遭檢舉之內容；惟若後續有爭議時本平台亦會查明後向相關權責單位提出申訴。</li>
                </ul>
                <p>第7條、守法義務及承諾</p>
                <ul>
                  <li>您承諾絕不為任何非法目的或以任何非法方式使用本平台，並承諾遵守您所屬國家及地區的相關法令法規及一切使用網際網路之國際慣例，且您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：</li>
                  <li>(1) 公開散佈或傳輸任何誹謗、侮辱、具威脅性、攻擊性、不實或其他不法之文字、圖片或任何形式的檔案；</li>
                  <li>(2) 無論真實與否，公開散佈或傳輸含有人獸交、公然裸露、性暴力、性虐待、性交易、兒少性剝削等無藝術性、醫學性或教育性價值之猥褻資訊 或物品（統稱硬蕊內容）；</li>
                  <li>(3) 張貼媒介性交易訊息、媒介性交易行為、邀約性交易或邀約引誘性交易（違反者將通報警察機關或司法警察機關）；</li>
                  <li>(4) 無論真實與否，涉及販賣、交換或介紹內容涉及真實兒少性活動、性暗示圖片、影片者；</li>
                  <li>(5) 無論真實與否，強調描繪兒少裸露、嬰幼兒性器官、兒少性相關部位，明顯與兒少性相關之表情、妝髮、穿著或肢體動作，明顯與兒少有性聯</li>
                  <li>(6) 無論真實與否，媒介、引誘、招募、邀約兒少為性活動（包含但不限於約炮、打手槍、援交妹招募等）、有對價關係性活動（包含但不限於援 交、幼女外送茶等）或傳送兒少性活動、兒少性擺拍畫面/影片。</li>
                  <li>(7) 無論真實與否，內容涉及性侵害、非自願性性虐待；</li>
                  <li>(8) 無論真實與否，內容涉及煽動、教唆殺害或傷害人類或動物者；</li>
                  <li>(9) 無論真實與否，內容涉及強調、凸顯或詳細描繪真實人類自殺、自殘或窒息式性愛等；</li>
                  <li>(10) 無論真實與否，內容涉及教導製造、取得或使用「法律定義之毒品、管制藥物或其主要原料」；</li>
                  <li>(11) 無論真實與否，內容涉及教導製造、改造或使用「法律所管制不得持有之槍砲彈藥刀械爆裂物為目的者」，為具有正面教育意義、正面價值或 正面形象時不在此限；</li>
                  <li>(12) 無論真實與否，內容涉及促銷或引誘兒少使用「菸、酒、檳榔」；</li>
                  <li>(13) 無論真實與否，內容涉及仇恨、歧視，或煽動、鼓吹仇恨與歧視；</li>
                  <li>(14) 無論真實與否，內容涉及針對特定人/可得特定人或族群進行恐嚇、威脅，且可預見危害他人生命、身體安全；</li>
                  <li>(15) 無論真實與否，內容涉及針對特定人/可得特定人或族群進行惡意辱罵、貶抑、否定人格、霸凌；</li>
                  <li>(16) 無論真實與否，內容涉及強調、凸顯或詳細描繪犯罪活動、手法；</li>
                  <li>(17) 內容涉及真實賭博內容；</li>
                  <li>(18) 內容涉及未經他人同意直接或間接識別他人之相關資訊（包含但不限於學校、姓名、地址、車牌、手機號碼或多人間接揭露他人個資可拼出完 整個資等）</li>
                  <li>(19) 侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利；</li>
                  <li>(20) 冒用他人名義使用本平台；</li>
                  <li>(21) 傳輸或散佈電腦病毒等妨害他人電腦使用之情事；</li>
                  <li>(22) 其他本平台有正當理由認為不適當之行為。</li>
                </ul>
                <p>第8條、本平台之停止與終止</p>
                <ul>
                  <li>有下列情形之一發生時，本平台有權不經通知即停止或中斷本平台：</li>
                  <li>(1) 因網路電子通訊設備進行必要之保養及施工。</li>
                  <li>(2) 因網路電子通訊設備發生突發性之故障。</li>
                  <li>(3) 因網路電子通訊服務被終止。</li>
                  <li>(4) 因天災或不可抗力因素。</li>
                  <li>(5) 因當地權責機關及政府之要求。</li>
                </ul>
                <p>第9條、智慧財產權與授權</p>
                <ul>
                  <li>(1) 您應保證您的內容之著作權人及著作所有權人均屬於您。</li>
                  <li>(2) 您應保證您的內容並無侵犯任何第三人的智慧財產權或法律上相關權益，若因您的內容導致本平台受有損害時，您應對本平台負擔相關損害賠 償責任，並以您自己之名義承擔相關責任。</li>
                  <li>(3) 若您有涉有侵害他人智慧財產權之相關情事達三次時，本平台將不經過任何通知，並以本平台單方面之決定，立即終止提供您在本平台之全部服務，您不得對本平台有任何請求。</li>
                  <li>(4) 您同意授權本平台，可以因為提供服務及贈獎等目的，而提供因為前面目的所必需的您的個人資料給本平台的合作夥伴，並於約定範圍內妥善 使用，若您不同意本平台將您的資料列於合作夥伴產品或服務名單內，可以通知本平台，將您於名單中刪除，同時放棄您的相關權利。</li>
                  <li>(5) 您因為成為本平台之會員而留存於本平台的個人資料，您同意本平台得於合理之範圍內蒐集、處理、保存、傳遞及使用，並提供您其他資訊或 服務、或作成統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。</li>
                  <li>(6) 任何內容一經您上載、傳送、輸入或提供至本平台時，視為您已允許本平台無條件使用、修改、重製、公開播送、改作、散布、發行、公開發 表該等資料，並得將前述權利轉授權他人，您對此絕無異議。</li>
                  <li>(7) 本平台所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計， 均屬 於本平台或其他權利人依法擁有的智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、 修改、 重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容， 必須依法取
                    得本平台或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本平台負損害賠償責任 （包括但不限於訴訟費用及 律師費用等）。</li>
                  <li>(8) 您於購買本平台之付費內容，僅限於您個人使用，您不得以任何方法（包含但不限於分享、重製、散布、公開發表、公開傳輸、公開播送等）供任何第三人觀覽，否則前述每一內容（或稱作品）之每一事件發生時，您同意無條件給付該作品售價之一百倍金額予本平台作為懲罰性違約金，並得因不同內容或事件而累加，且總額無上限。</li>
                  <li>(9) 除前述懲罰性違約金外，本平台得向您請求本平台因您的行為所導致之損失，若本平台無法證明損失時，則以您所獲得利益金額為損害賠償額，若本平台無法證明損失也無法知悉您所獲得利益金額時，以該作品售價之一百倍為損害賠償金額，並得因不同內容或事件而累加，且總額無上限。</li>
                </ul>
                <p>第10條、帳戶的關閉與刪除</p>
                <ul>
                  <li>(1) 本平台有權於下列情況下不附理由取消您的使用資格，或得以封鎖IP之方式封鎖您的使用資格，或拒絕您的註冊：</li>
                  <li>(i) 您的個人資料不完整或不真實；</li>
                  <li>(ii) 您違反本條款；</li>
                  <li>(iii) 因本平台之經營所在地之權責管理單位要求；</li>
                  <li>(iv) 未經本平台同意之帳號所有權移轉或買賣；</li>
                  <li>(v) 您侵害他人權利。</li>
                  <li>(vi) 因司法或金融機構之調查程序；</li>
                  <li>(vii) 未經本平台同意的任何形式退款（包含但不限於否認交易或盜刷等情形）。</li>
                  <li>(2) 若您有違反本條款第六條之情形，本平台有權利立即終止向您提供服務，並關閉或刪除您的帳號，另若涉及兒少性剝削內容，本平台將依《兒 少性剝削防制條例第八條》及iWIN自律通報流程，通報相關警政。</li>
                  <li>(3) 若您有違反本條款除第六條以外之情形，本平台將會盡可能的通知您儘速改善，惟若您違反本條款達三次時，本平台有權立即終止向您提供服 務，並關閉或刪除您的帳號。</li>
                  <li>(4) 您可以要求本平台將您的會員資料及個人資料刪除；您同意，一旦您要求刪除您在本平台的全部或部份的會員資料或個人資料，本平台將完全 刪除您在本平台所有的會員資料及個人資料（包含但不限於已購買內容、會員資料、個人資料、瀏覽紀錄等）且無法要求復原，但本平台有權保留 營業必要的數據。</li>
                </ul>
                <p>第11條、 購買與付款</p>
                <ul>
                  <li>(1) 本平台可能提供免費瀏覽、查看或使用本平台之部分內容，但您存取或使用特定免費內容時，必須遵守其內容的額外限制。</li>
                  <li>(2) 當您透過或使用本平台購買內容時，即視同您與本平台間依據本條款而進一步成立買賣合約。</li>
                  <li>(3) 當發生第6條第9項之依法限時下架情形時，您理解經本平台下架之商品將無法繼續觀看，該部作品之價金亦無法返還予您。</li>
                </ul>
                <p>第12條、 訂閱方案</p>
                <ul>
                  <li>(1) 本平台之會員可以依其喜好及自由意願選擇訂閱特定之模特兒會員，並得在訂閱期間觀覽模特兒會員所發佈之限時或永久之上傳內容。</li>
                  <li>(2) 前述訂閱之行為可能為免費或需要付費。</li>
                  <li>(3) 當您開始訂閱特定模特兒會員時，您即認知且明示同意以您所選擇的付款方式定期支付您所選擇之訂閱服務期間費用，直到您取消訂閱特定模 特兒會員或由本平台終止您的本平台帳戶及服務。會員訂閱特定模特兒會員後，可於一個月內於該名模特兒會員的專屬頁面中無限次收看模特兒會 員所分享的無限時內容或有限時內容。但若您未使用或逾期視同放棄，不得要求遞延或補償。</li>
                  <li>(4) 若您欲取消訂閱，請於次月扣款日七個工作天前，洽詢本平台之服務人員並告知欲取消訂閱的特定模特兒會員（例：次月扣款日為2022年4月 15日，請於2022年4月8日前取消月租方案，以此類推）。取消手續成功之後，您欲取消的特定模特兒會員之後續自動扣款將於次期停止，會員可
                    使用權益也將至當期結束。若會員在次月扣款日前七個工作天內申請取消訂閱，則次月也將被扣款，會員之訂閱權益將遞延一個月，於下下月停止 扣款（例：次月扣款日為2022年4月15日，若會員於2022年4月12日申請取消，則2022年4月15日一樣會被扣款，月租方案會員權益從2022年4月
                    15日順延一個月，2022年5月15日停止扣款轉為基本會員身分，從以此類推。）在訂閱期間內，會員不得針對任何未使用期間主張退款。</li>
                  <li>(5) 若本服務無法對您所提供的付款方式進行扣款（例如信用卡過期、您未付款/拒絕付款、信用卡額度不足&hellip;等）時，本服務將有權利立即終止您 的訂閱。</li>
                  <li>(6) 價格變動：模特兒會員得自行決定於任何時候、以任何方式調整訂閱之收費額度或方式，變動價格將於次月生效。</li>
                  <li>(7) 不予退款：本平台將不提供退款服務，於極端例外情況下，例如因模特兒帳號關閉或當本平台服務因技術上困難而無法使用，本平台基於自己 之專屬裁量判斷，得提供受影響之顧客合理補償。本平台得自行決定補償方案之額度與形式，且該消費補償方案屬一次性之提供，並不使任何人得 因此於未來相同之情況下享受該權利。當您取消訂閱時，仍得於剩餘訂閱期間使用該服務。</li>
                </ul>
                <p>第13條、千禧年法案、法律遵循及侵權</p>
                <ul>
                  <li>(1) 若您有發現SEEWE有任何違反千禧年法案或是侵犯第三人權利的情形時，請透過我們法務團隊的信箱與我們聯繫：ourseewe@gmail.com。</li>
                  <li>(2) 若您有發現任何第三人侵犯SEEWE的智慧財產權時，請透過我們法務團隊的信箱與我們聯繫：ourseewe@gmail.com。</li>
                </ul>
                <p>第14條、第三方費用</p>
                <ul>
                  <li>您使用及瀏覽內容時，如有任何第三方（例如您的網際網路供應商或電信業者）向您收取數據傳輸或存取費用，您必須自行支付。</li>
                </ul>
                <p>第15條、稅金</p>
                <ul>
                  <li>您必須支付所有因購買內容所產生的相關稅款、稅捐或稅金，包含任何罰金或利息，因此您支付的內容款項不得扣除稅金。</li>
                </ul>
                <p>第16條、退貨與退款</p>
                <ul>
                  <li>(1) 您所購買的內容，一概不得退換或退款；惟依照您所在地之相關法令法規若有特別規定得向本平台申請退款時，則您得依據該法規向本平台請求退款，但若您所購買的內容已經經過使用或瀏覽，則本平台得拒絕退費。如本平台有任何交易允許退換商品或退款，則您將無法再使用透過該筆交易取得的內容。</li>
                </ul>
                <p>第17條、準據法與管轄法院</p>
                <ul>
                  <li>(1) 您同意本條款若有尚未詳細約定的事項，都以本平台營業所在地之相關法令法規為準據法。</li>
                  <li>(2) 您同意因為本條款或本平台而與您之間發生訴訟時，以本平台所在地之當地地方法院為唯一的第一審管轄法院。</li>
                </ul>
                <p>&nbsp;</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms'
}
</script>

